<script setup lang="ts">
import type { Product, ProductVariant } from 'types/models/product'
import { wait } from 'lib/helpers/wait'
import { productUrl } from 'lib/routing'
import ProductAddToBuyWithPrimeCart from './ProductAddToBuyWithPrimeCart.vue'
import { useSpeechBubbleStore } from '~/stores/speechBubble'
import type { AddToCartSources } from '~/types/gtm'
import { getDefaultVariant } from '~/utils/products/getDefaultVariant'

interface Props {
  product: Product
  sticky?: boolean
  showPdpLink?: boolean
  pageSource?: AddToCartSources
  isQuickShop?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  pageSource: 'otherPage',
  isQuickShop: false,
})

const emit = defineEmits(['closeQuickShop'])
const gtm = useGTM()
const cartStore = useCartStore()
const speechBubbleStore = useSpeechBubbleStore()
const sizeBuyGroup = ref<HTMLElement>()
const { $t } = useNuxtApp()
const storefrontStore = useStorefrontStore()
const { currentStorefrontCode } = storeToRefs(storefrontStore)
const bwp = useBuyWithPrimeWidget()

const state = reactive({
  defaultVariant: getDefaultVariant(props.product),
  selectedVariant:
    props.product.variants.length === 1 ? getDefaultVariant(props.product) : undefined,
  addingProduct: false,
  addedProduct: false,
  forceSelection: false,
  noSize: props.product.variants[0]!.size === '0',
  noStock: props.product.stockStatus === 'no-stock',
})

const isPrimeProduct = computed(() => currentStorefrontCode.value === 'us'  && !props.isQuickShop && props.product.categories.includes('buy-with-prime'))

function onChangeVariant(variant: ProductVariant) {
  state.selectedVariant = variant
  bwp.updateBwpVariant(variant, props.isQuickShop, props.product)
}

function onForceSelection() {
  state.forceSelection = true
}

const onSelectionClosed = () => (state.forceSelection = false)

async function onSubmit() {
  if (!state.selectedVariant) {
    state.forceSelection = true
    return
  }

  state.addingProduct = true

  try {
    gtm.pushAddToCart({ item: props.product, variant: state.selectedVariant, source: props.pageSource })
    await cartStore.addItem(state.selectedVariant.id)
    speechBubbleStore.setSpeechBubble('transaction', speechBubbleStore.getStates()?.addToCart, 2500)
    emit('closeQuickShop')
  }
  catch (e: EvalError | any) {
    if (e.message)
      cartStore.state.messages.push(e.message)
  }

  wait(300).then(() => {
    state.addingProduct = false
    state.addedProduct = true
  })
}
</script>

<template>
  <form class="form" @submit.prevent="onSubmit">
    <div
      ref="sizeBuyGroup"
      class="size-buy-group" :class="[
        {
          'no-size': state.noSize,
          'sticky': props.sticky,
        },
      ]"
    >
      <ProductSizeSelector
        :key="product.id"
        :variants="product.variants"
        :selected="state.selectedVariant"
        :force-open="state.forceSelection"
        data-test="product-size-selector"
        @change-variant="onChangeVariant"
        @selection-closed="onSelectionClosed"
      />
      <ButtonBasic
        tag="button"
        native-type="submit"
        size="medium"
        :uppercase="true"
        :bold="true"
        class="add-to-cart-btn" :class="[{ adding: state.addingProduct, 'prime-product': isPrimeProduct && !state.selectedVariant?.sku}]"
        data-test="product-add-to-cart-button"
        design="radius-without-border"
        background-color="var(--green)"
        hover-color="var(--green-hover)"
        :disabled="state.noStock || state.addingProduct"
        :aria-label="$t('addToCart')"
      >
        <LoadingSpinner
          v-if="state.addingProduct"
          :show-check="state.addedProduct"
          @complete="state.addingProduct = false"
        />
        <template v-else>
          <span v-if="!state.noStock" class="add-text">{{ $t('addToCart') }}</span>
          <span v-else class="normal">{{ $t('productOutOfStock') }}</span>
        </template>
      </ButtonBasic>
      <p v-if="isPrimeProduct && !state.selectedVariant?.sku" :class="[{ or: isPrimeProduct}]">OR</p>
      <ProductAddToBuyWithPrimeCart
        v-if="isPrimeProduct"
        :product-sku="state.selectedVariant?.sku"
        :on-force-selection="onForceSelection"
      />
      <ButtonIcon
        v-if="showPdpLink"
        class="pdp-link"
        :text="$t('quickBuyProductDetails')"
        right-icon="forward"
        background-color="transparent"
        target="_self"
        tag="nuxt-link"
        :to="productUrl(product.sku)"
      />
    </div>
  </form>
</template>

<style lang="scss" scoped>
.form {
  display: contents;
}
.size-buy-group {
  width: 100%;
  display: grid;
  border-top: 1px solid var(--gray-light);
  grid-template-columns: 1fr;
  gap: 0.8rem;
  .size-dropdown {
    width: 100%;
    display: block;
    height: 3.5rem;
    font-weight: 700;
  }
  .bwp-wrapper {
    width: 100%;
  }
  .pdp-link {
    border: 1px solid black;
    text-align: center;
    justify-content: center;
    height: 4rem;
    border: none;
  }

  &.no-size {
    grid-template-columns: 1fr;
    .size-selector {
      display: none;
    }
  }
}
.sticky {
  z-index: 10;
  top: 10.8rem;
  position: sticky;
}
.or {
  text-align: center;
  margin-bottom: -1.3rem;
}
.prime-product {
  margin-top: 0.4rem;
  height: 5.5rem;
}
</style>
